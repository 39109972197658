import React from "react";

export const Button = (props) => {
  return (
    <button
      className={`btn btn-${props.shape} btn-${props.color} ${props.action}`}
      onClick={props.onclick}
    >
      <span className={props.span}>{props.buttontext}</span>
    </button>
  );
};

export const ScrollButton = (props) => {
  return (
    <button
      className={`btn btn-${props.shape} btn-${props.color} ${props.action}`}
      onClick={props.onclick}
      style={{
        visibility: props.visible ? "visible" : "hidden",
        transition: "visibility 0.5s, opacity 0.5s linear",
        opacity: props.visible ? 1 : 0,
      }}
    >
      {props.buttontext}
    </button>
  );
};
