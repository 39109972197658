export default function AboutPage() {
  return (
    <div className="content">
      <p className="font-hell page-title border-bot">
        about: digital purgatory
      </p>
      <p>
        In the world of non-fungible tokens (NFTs), the act of burning NFTs has
        become commonplace as a mechanism to unlock additional utility by the
        sacrifice of an existing token, or simply as a way to rid ones wallet of
        spam and evidence of failed projects.
      </p>
      <p>
        By burning an NFT one "destroys" it by sending it to an inaccessible
        address, permanently removing it from circulation, rending it untradable
        or sellable. It is here that the token lingers for the enduring
        existence of the blockchain, expunged of market speculation and
        collectability through a digital ritual that teeters somewhere between
        the act of cremation and emptying your email inbox of black friday spam.
      </p>
      <p>
        0xdEaD was created to provide a space on the internet where one can
        acknowledge the NFT's that have made the journey to these addresses.
        Allowing one to wander aimlessly through the evidence of our collective
        cyber hoarding and ruins of digital accumulation.
      </p>
      <p className="font-serif goodbye italic glitch">
        Goodbye, my friend, the NFT.
      </p>
    </div>
  );
}
