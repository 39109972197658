import React from "react";
import { FooterLinks } from "./links";

function Footer() {
  return (
    <div className="footer-container">
      <div className="footer">
        <div className="footer-col">
          <span className="nav-content">
            <img
              className="footer-logo"
              src="./angel-bitmap-small.png"
              alt="bitmap angel"
            />
          </span>
          <span className="nav-content center">©2022 0xdEaD</span>
        </div>
        <div className="footer-col-text">
          <span className="subtitle glitch font-hell">Special mentions</span>
          <span>
            Thank you{" "}
            <FooterLinks link={"https://moralis.io/"} linktext={"Moralis"} />,
            <FooterLinks
              link={"https://flipsidecrypto.xyz/"}
              linktext={"Flipside"}
            />{" "}
            and{" "}
            <FooterLinks link={"https://www.infura.io/"} linktext={"Infura"} />{" "}
            for providing services that support small builders.
          </span>
        </div>
        <div className="footer-col-text">
          <span className="subtitle glitch font-hell">Contact</span>
          <span>
            Thank you for visiting, you can contact me at{" "}
            <FooterLinks
              link={"mailto: hell@0xdead.co"}
              linktext={"hell@0xdead.co"}
            />{" "}
            or{" "}
            <FooterLinks
              link={"https://twitter.com/scopecr33p"}
              linktext={"Twitter"}
            />
          </span>
        </div>
      </div>
    </div>
  );
}

export default Footer;
