import React from "react";

function CurvedText(props) {
  return (
    <svg viewBox="0 -2 100 100" preserveAspectRatio="xMidYMid meet">
      <path id="arc" d="M 4 50 a 20 20 0 0 1 92 0" fill="none" />
      <text textAnchor="middle" fontSize="90%">
        <textPath className="font-hell" href="#arc" startOffset="50%">
          R.I.P {props.text}
        </textPath>
      </text>
    </svg>
  );
}

export default CurvedText;
