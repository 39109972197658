import React, { useEffect, useState } from "react";
import { BodyLink } from "../components/links";
import { LineChart, Line, XAxis, Tooltip, ResponsiveContainer } from "recharts";
import TypedLoader from "../components/loader";

export default function DataPage() {
  const [loading, setLoading] = useState(false);
  const [graphData, setGraphData] = useState([]);
  const [statData1, setStatData1] = useState([]);
  const [statData2, setStatData2] = useState([]);
  const [statData3, setStatData3] = useState([]);
  const [statData4, setStatData4] = useState([]);

  // Flipside urls for the data
  const statDataURL1 =
    "https://node-api.flipsidecrypto.com/api/v2/queries/f62c9958-7692-42f8-b120-d8fde9aec45a/data/latest";
  const statDataURL2 =
    "https://node-api.flipsidecrypto.com/api/v2/queries/d62bd1be-1ffd-410f-b10c-a9be1248ac72/data/latest";
  const statDataURL3 =
    "https://node-api.flipsidecrypto.com/api/v2/queries/f8876d60-9667-41ce-a9d1-4af598239c23/data/latest";
  const statDataURL4 =
    "https://node-api.flipsidecrypto.com/api/v2/queries/8edce46f-7804-4fc2-8fde-46ad9c845660/data/latest";
  const graphDataURL =
    "https://node-api.flipsidecrypto.com/api/v2/queries/1ea096d2-84b7-43d0-ab63-46b113d5e1a1/data/latest";

  // Fetch request for the data, save the responses to state
  useEffect(() => {
    setLoading(true);
    if (statData1.length === 0) {
      const loadData = async () => {
        const response1 = await fetch(statDataURL1);
        const statData1 = await response1.json();
        setStatData1(statData1);

        const response2 = await fetch(statDataURL2);
        const statData2 = await response2.json();
        setStatData2(statData2);

        const response3 = await fetch(statDataURL3);
        const statData3 = await response3.json();
        setStatData3(statData3);

        const response4 = await fetch(statDataURL4);
        const statData4 = await response4.json();
        setStatData4(statData4);
        setLoading(false);
      };
      loadData();
    }
  }, [statData1, statData2, statData3, statData4]);

  useEffect(() => {
    setLoading(true);
    if (graphData.length === 0) {
      const loadData = async () => {
        await fetch(graphDataURL)
          .then((response) => response.json())
          .then((data) => {
            setLoading(false);
            setGraphData(data);
          });
      };
      loadData();
    }
  }, [graphData]);

  const lineBurnData = graphData.map((item) => {
    return {
      week: item.WEEK.substring(0, 10),
      NFTS_burnt: item.NFTS_BURNT,
    };
  });

  // Sort data in the object by date
  lineBurnData.sort((a, b) => {
    const dateA = new Date(a.week);
    const dateB = new Date(b.week);

    if (dateA.getTime() < dateB.getTime()) return -1;
    if (dateA.getTime() > dateB.getTime()) return 1;
    return 0;
  });

  // Shorten number function
  function abbreviateNumber(number) {
    if (number < 1000) {
      return number;
    }

    let suffixes = ["", "k", "m", "b", "t"];
    let suffixNum = Math.floor(("" + number).length / 3);
    let shortValue = parseFloat(
      (suffixNum !== 0
        ? number / Math.pow(1000, suffixNum)
        : number
      ).toPrecision(4)
    );
    if (shortValue % 1 !== 0) number = shortValue.toFixed(1);
    return shortValue + suffixes[suffixNum];
  }

  // Inline styles for graph
  const labelStyle = {
    color: "#786eff",
  };

  const itemStyle = {
    color: "#786eff",
  };

  return (
    <div className="content">
      <p className="font-hell page-title border-bot">
        data: observations &nbsp;at &nbsp;a funeral{" "}
      </p>
      <p className="">
        This data is currently only avalible for the etheruem burn wallet{" "}
        <BodyLink
          link="https://etherscan.io/address/0x000000000000000000000000000000000000dead"
          linktext="0xdEaD"
        />
        . View this dashboard and queries over at{" "}
        <BodyLink
          link="https://app.flipsidecrypto.com/dashboard/0-xd-ea-d-nft-burns-%F0%9F%94%A5-4HtWz4"
          linktext="Flipside"
        />{" "}
        or <BodyLink link="https://twitter.com/scopecr33p" linktext="submit" />{" "}
        a requests for further burn stats.
      </p>
      <div className="data">
        <div className="graph vitals">
          <span className="graphInfo">NFTs burns over time</span>
          {loading && lineBurnData.length === 0 ? (
            <div className="loading-container" id="loading">
              <TypedLoader />
            </div>
          ) : (
            <ResponsiveContainer width="100%" height={500}>
              <LineChart
                data={lineBurnData}
                margin={{
                  top: 10,
                  right: 0,
                  left: 0,
                  bottom: 10,
                }}
              >
                <XAxis dataKey="week" hide={true} />
                <Tooltip labelStyle={labelStyle} itemStyle={itemStyle} />
                <Line
                  type="monotone"
                  dataKey="NFTS_burnt"
                  stroke="#b1fca3"
                  reference="week"
                  dot={false}
                />
              </LineChart>
            </ResponsiveContainer>
          )}
        </div>
        <div className="stats-container">
          <div className="stats vitals green">
            <span className="small light-grey">NFTs sent to 0xdEaD</span>
            <span className="graphInfo">NFTS Burnt</span>
            {statData1.length === 0 ? (
              <p>Loading....</p>
            ) : (
              <span className="graphInfo big">
                {Object.values(statData1[0])}
              </span>
            )}
          </div>

          <div className="stats vitals purple">
            <span className="small light-grey">NFTs burnt in past 24hr</span>
            <span className="graphInfo">24hr Burn Rate</span>
            {statData2.length === 0 ? (
              <p>Loading....</p>
            ) : (
              <span className="graphInfo big">
                {Object.values(statData2[0])}
              </span>
            )}
          </div>

          <div className="stats vitals blue">
            <span className="small light-grey">Unique wallet count</span>
            <span className="graphInfo">Burners</span>
            {statData3.length === 0 ? (
              <p>Loading....</p>
            ) : (
              <span className="graphInfo big">
                {Object.values(statData3[0])}
              </span>
            )}
          </div>

          <div className="stats vitals pink">
            <span className="small light-grey">Value at time of mint</span>
            <span className="graphInfo">Value Burnt</span>
            {statData4.length === 0 ? (
              <p>Loading....</p>
            ) : (
              <span>
                <span className="graphInfo big">
                  {abbreviateNumber(Number(Object.values(statData4[0])))}
                </span>
                <span className="small">ETH</span>
              </span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
