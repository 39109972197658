export default function ErrorPage() {
  return (
    <div className="content">
      <div className="error-msg">
        <header className="header font-hell glitch">RIP</header>
        <p>
          Oops, it looks like you're six feet under and can't access this page.
        </p>
      </div>
    </div>
  );
}
