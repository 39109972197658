import React, { useState, useEffect, useRef } from "react";
import { Route, Routes, Outlet, Navigate } from "react-router-dom";
import AboutPage from "./routes/about";
import DataPage from "./routes/data";
import ErrorPage from "./routes/error";
import Footer from "./components/footer";
import Navigation from "./components/nav";
import NftCard from "./components/nftCard";
import TypedLoader from "./components/loader";
import { Button, ScrollButton } from "./components/buttons";

export default function App() {
  // states for scroll up button
  const [scrollPosition, setScrollPosition] = useState(0);
  const [isVisible, setIsVisible] = useState(false);

  // scroll to top of page
  const scrollUp = () => {
    mainContainer.current.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  // scroll to top of cards
  let scrollCards = () => {
    const height = window.innerHeight;
    const scrollTop = height < 899 ? 100 : 400;
    mainContainer.current.scrollTo({
      top: scrollTop,
      left: 0,
      behavior: "smooth",
    });
  };

  // handle button visiblity
  useEffect(() => {
    mainContainer.current.addEventListener("scroll", listenToScroll);
    return () =>
      mainContainer.current.removeEventListener("scroll", listenToScroll);
  }, []);

  //set conditional rendering of scroll button
  const listenToScroll = () => {
    let scrollLimit = 300;
    const winScroll =
      mainContainer.current.scrollTop || mainContainer.current.scrollTop;

    if (winScroll > scrollLimit && window.location.pathname === "/") {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  // apply useRef to main container for scroll up button
  const mainContainer = useRef("container");

  return (
    <div className="overlay">
      <div className="container body-outline" ref={mainContainer}>
        <Navigation />
        <div className="body-container w-100">
          <Outlet />
          <Routes>
            <Route path="/">
              <Route index element={<Cards resetScroll={scrollCards} />} />
              <Route path="about" element={<AboutPage />} />
              <Route path="data" element={<DataPage />} />
            </Route>
            <Route path="error" element={<ErrorPage />} />
            <Route path="*" element={<Navigate to="error" />} />
          </Routes>
          <ScrollButton
            onclick={() => scrollUp()}
            buttontext="⬆ SCROLL UP"
            color="green"
            shape="round"
            action="scroll"
            visible={isVisible}
          />
        </div>
        <Footer />
      </div>
    </div>
  );
}

function Cards({ resetScroll }) {
  const [loading, setLoading] = useState(false);
  const [ethData, setEthData] = useState([]);
  const [ftmData, setFtmData] = useState([]);
  // if chain true = eth, if chain false = ftm
  const [chain, setChain] = useState(true);
  const [cursorFtm, setCursorFtm] = useState(null);
  const [cursorEth, setCursorEth] = useState(null);
  const [shouldLoadMore, setShouldLoadMore] = useState(false);
  const [err, setErr] = useState(false);

  // nft data from moralis
  let urlEth = `https://deep-index.moralis.io/api/v2/0x000000000000000000000000000000000000dEaD/nft?chain=eth&format=decimal&limit=6&disable_total=true&media_items=false`;
  let urlFtm = `https://deep-index.moralis.io/api/v2/0x000000000000000000000000000000000000dEaD/nft?chain=fantom&format=decimal&limit=6`;

  const headers = {
    accept: "application/json",
    "X-API-Key":
      "qXnhLEdmx8b0e8Qwy3LAqkBh9JmD2at3ZCRignia3C1k0f736ERUrwkM9slw9Js7",
  };

  // update url with ftm cursor
  if (cursorFtm !== null) {
    if (urlFtm.includes("&cursor=")) {
      urlFtm = urlFtm.split("&cursor=")[0] + `&cursor=${cursorFtm}`;
    } else {
      urlFtm = urlFtm + `&cursor=${cursorFtm}`;
    }
  }

  // update url with eth cursor
  if (cursorEth !== null) {
    if (urlEth.includes("&cursor=")) {
      urlEth = urlEth.split("&cursor=")[0] + `&cursor=${cursorEth}`;
    } else {
      urlEth = urlEth + `&cursor=${cursorEth}`;
    }
  }

  // toggle between eth and ftm hook
  useEffect(() => {
    chain ? setChain(true) : setChain(false);
  }, [chain]);

  useEffect(() => {
    if (chain === false) {
      if (shouldLoadMore || ftmData.length === 0) {
        setLoading(true);
        const loadFtmData = async () => {
          const data = await fetch(urlFtm, { headers }).then((response) => {
            if (response.status >= 200 && response.status <= 299) {
              return response.json();
            } else {
              setErr(true);
              throw Error(response.statusText);
            }
          });
          setLoading(false);
          setShouldLoadMore(false);
          setCursorFtm(data.cursor);
          setFtmData([...ftmData, ...data.result]);
        };
        loadFtmData();
      }
    } else {
      if (shouldLoadMore || ethData.length === 0) {
        setLoading(true);
        const loadEthData = async () => {
          const data = await fetch(urlEth, { headers }).then((response) => {
            if (response.status >= 200 && response.status <= 299) {
              return response.json();
            } else {
              setErr(true);
              throw Error(response.statusText);
            }
          });
          setLoading(false);
          setCursorEth(data.cursor);
          setEthData([...ethData, ...data.result]);
          setShouldLoadMore(false);
        };
        loadEthData();
      }
    }
  }, [chain, shouldLoadMore, err]);

  //random image from the array for missing images
  const imageArray = ["/glitch.png", "/angel-crop.png"];
  let randomImage = (images) => {
    return images[Math.floor(Math.random() * images.length)];
  };

  // if image url is ifps prefix update to gateway
  // update to this url to before launch
  const replaceIpfs = (url) => {
    if (url === null || url === undefined) {
      return randomImage(imageArray);
    } else if (url.includes("ipfs://ipfs/")) {
      return ["", url.split("ipfs://ipfs/")[1]].join(
        "https://dead.infura-ipfs.io/ipfs/"
      );
    } else if (url.includes("ipfs://")) {
      return ["", url.split("ipfs://")[1]].join(
        "https://dead.infura-ipfs.io/ipfs/"
      );
    } else {
      return url;
    }
  };

  // if name is missing update
  const replaceName = (name) => {
    return name === null || name === undefined ? "𝖀𝖓𝖐𝖓𝖔𝖜𝖓" : name;
  };

  // structure response data, remove any items will null metadata
  const metaData = (chain ? ethData : ftmData).flatMap((item) => {
    if (JSON.parse(item.metadata) === null) {
      return {
        collection: item.name,
        token_hash: item.token_hash,
        token_address: item.token_address,
        token_id: item.token_id,
        symbol: item.symbol,
        chain: chain ? "ETH" : "FTM",
        name: "𝖀𝖓𝖐𝖓𝖔𝖜𝖓",
        image: randomImage(imageArray),
      };
    }
    return {
      collection: item.name,
      token_hash: item.token_hash,
      token_address: item.token_address,
      token_id: item.token_id,
      symbol: item.symbol,
      chain: chain ? "ETH" : "FTM",
      name: replaceName(JSON.parse(item.metadata)["name"]),
      image: replaceIpfs(JSON.parse(item.metadata)["image"]),
    };
  });

  const nftList = metaData.map((nft) => (
    <NftCard
      id={nft.token_id}
      symbol={nft.symbol}
      collection={nft.collection}
      name={nft.name}
      image={nft.image}
      chain={nft.chain}
      token_hash={nft.token_hash}
      token_address={nft.token_address}
      key={nft.token_hash}
    />
  ));

  const cardRender = err ? (
    <>
      <span className="">Sorry there was an error, try again or refresh</span>
    </>
  ) : (
    nftList
  );

  return (
    <>
      {loading && err === false && nftList.length === 0 ? (
        <div className="loading-container" id="loading">
          <TypedLoader />
        </div>
      ) : (
        <>
          <div className="cards" id="cards">
            {cardRender}
          </div>

          <div className="on-page-nav">
            {loading && err === false ? (
              <div className="loading-container">
                <TypedLoader />
              </div>
            ) : null}
            {err === true ? (
              <Button
                onclick={() => {
                  setShouldLoadMore(true);
                  setErr(false);
                }}
                buttontext="Try Again"
                color="green"
                shape="oval"
              />
            ) : (
              <Button
                onclick={() => setShouldLoadMore(true)}
                buttontext="LOAD MORE"
                color="green"
                shape="oval"
              />
            )}
          </div>
          <Button
            onclick={() => {
              setChain(chain ? false : true);
              resetScroll();
            }}
            buttontext={`SWITCH TO ${chain ? "FTM" : "ETH"} ⮐`}
            color={`${chain ? "blue" : "purple"}`}
            shape="sqr"
            span="upside"
            action="flip"
          />
        </>
      )}
    </>
  );
}
