import React from "react";
import { NavigationLink } from "./links";

export default function Navigation() {
  return (
    <div className="nav-container w-100">
      <div className="nav-container border-bot w-100">
        <header className="header font-hell glitch">
          <a href="/" className="header">
            0xdEaD
          </a>
        </header>
      </div>
      <div className="navigation box-glitch glow-gray sticky">
        <NavigationLink link="/" linktext="Home" />
        <NavigationLink link="/about" linktext="About" />
        <NavigationLink link="/data" linktext="Data" />
      </div>
    </div>
  );
}
