import React from "react";

function MediaDisplay(props) {
  // Check the file extension of the `src` to determine the type of media to render
  if (
    props.src.endsWith(".jpg") ||
    props.src.endsWith(".png") ||
    props.src.endsWith(".gif")
  ) {
    // Render an `img` element if the `src` is an image file
    return (
      <img
        className="card-img arch border"
        src={props.src}
        alt={`NFT #${props.alt}`}
      />
    );
  } else if (props.src.endsWith(".mp4") || props.src.endsWith(".avi")) {
    // Render a `video` element if the `src` is a video file
    return <video className="card-img arch border" src={props.src} controls />;
  } else {
    // Catch all other, assume image is most likely media type
    return (
      <img
        className="card-img arch border"
        src={props.src}
        alt={`NFT #${props.alt}`}
      />
    );
  }
}

export default MediaDisplay;
