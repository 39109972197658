import React, { useState } from "react";

function TypedLoader() {
  const text = "finding the bodies.▯♰..▯..";
  const animationSpeed = 100;

  // Index of current displayed character
  const [index, setIndex] = useState(0);

  // Set the text element to display and empty string
  const [typedText, setTypedText] = useState(" ");

  // Use setInterval to call the type() function as often as set in animiationSpeed var
  React.useEffect(() => {
    const interval = setInterval(type, animationSpeed);
    return () => clearInterval(interval);
  }, [type]);

  function type() {
    const char = text.charAt(index % text.length);

    if (typedText.length >= text.length) {
      setIndex(0);
      setTypedText("");
    } else {
      setIndex((index + 1) % text.length);
      setTypedText(typedText + char);
    }
  }
  return <span className="text">{typedText}</span>;
}

export default TypedLoader;
