import React from "react";
import { NavLink } from "react-router-dom";

export const NavigationLink = (props) => {
  let activeClassName = "active-link nav-link pill";

  return (
    <NavLink
      to={props.link}
      className={({ isActive }) =>
        isActive ? activeClassName : "nav-link pill"
      }
    >
      {props.linktext}
    </NavLink>
  );
};

export const CardLinks = (props) => {
  return (
    <a className="card-link" href={props.link} target="_blank" rel="noreferrer">
      {props.linktext}➚
    </a>
  );
};

export const BodyLink = (props) => {
  return (
    <a className="body-link" href={props.link} target="_blank" rel="noreferrer">
      {props.linktext}➚
    </a>
  );
};

export const FooterLinks = (props) => {
  return (
    <a
      className="footer-link"
      href={props.link}
      target="_blank"
      rel="noreferrer"
    >
      {props.linktext}➚
    </a>
  );
};
