import React from "react";
import CurvedText from "./curvedText";
import MediaDisplay from "./mediaDisplay";
import { CardLinks } from "./links";

function NftCard(props) {
  return (
    <div className="card arch border" id={props.id}>
      <div className="card-top">
        <span className="card-name glitch">
          <CurvedText text={props.name}></CurvedText>
        </span>
        <div className="card-media">
          <MediaDisplay src={props.image} alt={props.id} />
        </div>
      </div>
      <div className="card-content">
        <div className="unicode-divider">‿̩͙‿ ༺ ♰ ༻ ‿̩͙‿</div>

        <div className="card-row">
          <span className="card-subtitle">Collection </span>
          <CardLinks
            link={
              props.chain === "ETH"
                ? `https://etherscan.io/token/${props.token_address}`
                : `https://ftmscan.com/token/${props.token_address}`
            }
            linktext={`${props.collection}`}
          />
        </div>
        <div className="card-row">
          <span className="card-subtitle">Token </span>
          <CardLinks
            link={
              props.chain === "ETH"
                ? `https://etherscan.io/token/${props.token_address}?a=${props.id}`
                : `https://ftmscan.com/token/${props.token_address}?a=${props.id}`
            }
            linktext={`${props.token_hash}`}
          />
        </div>

        <div className="card-tags">
          <span className={`tag tag-${props.chain}`}>{props.chain}</span>
          <span className={`tag tag-${props.chain}`}>{props.symbol}</span>
        </div>
      </div>
    </div>
  );
}

export default NftCard;
